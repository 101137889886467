
.textBoxxStyle {

    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;; */
    /* color:rgba(0, 0, 0, 0.6); */
    color:rgba(0, 0, 0, 0.87);

    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    padding-block: 2%;
    /* padding-block:1%;
    align-items: center;
    align-self: flex-start; */

}

ul{
    font-weight: 100%;
    font-size:100%;
    margin:0;
    padding:0;
    color:rgba(0, 0, 0, 0.87);
}