.button-style {
    height: 25%;
    padding: 1%;
    border-radius: 5px;
    color:rgba(0, 0, 0, 0.87);
  }
  
  .button-style:hover{
  
      background-color:rgba(0, 0, 0, 0.12)
  
  }