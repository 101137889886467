.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex: column;
  flex-direction: column;
}

.childrenContainer {
  height: 85%;
  /* background-color: #121212; */
  /* overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;  */
}

/* .childrenContainer::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
