.App {
  margin: 0;
  text-align: center;
  height: 100vh;
  width: 100vw;
  color: rgba(0, 0, 0, 0.87)
}

.cookiePolicy {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border: 0;
}

.serviceContainer {
  border-radius: 10px;
  border-color: black;
  /* background-color: white; */
  border-width: 5px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* .serviceContainer::-webkit-scrollbar {
  display: none;
} */
