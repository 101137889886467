.button-style {
    height: 25%;
    /* width:100%; */
    border-radius: 5px;
    color:rgba(0, 0, 0, 0.87);
    
  }
  
  .button-style:hover{
  
      background-color:rgba(0, 0, 0, 0.12)
  
  }

/* .button-group{
    display:flex;
    justify-content: space-evenly;
    width:100%;
    padding: 5% !important;
} */

.button-divider{
    display: flex;
    justify-content: space-around; 
    /* margin:10px; */
    align-content: center; 
    flex-direction: row;
    align-items: center;
}