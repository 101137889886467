.documentViewer .react-pdf__Document {
  display: flex;
  /* height:105%; */
  flex-direction: column;
  align-items: center;
}

.documentViewer .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  align-self: center;
  align-content: center;
  margin: 1em;
}

.documentViewer .react-pdf__Page canvas {
  max-width: 100%;
  max-height: 100%;
  align-content: center;
  align-self: center;
  text-align: center;
  /* height: auto !important; */
}

.documentViewer .react-pdf__message {
  /* padding: 20px; */
  color: white;
}

.serviceContainer {
  border-radius: 10px;
  border-color: black;
  /* background-color: white; */
  border-width: 5px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  /* -ms-overflow-style: none;  
     scrollbar-width: none;
  */
}

.serviceContainer::-webkit-scrollbar {
  display: none;
}
