@keyframes discord {
	0% {height:70px; width:250px;}
	 100% {height:500px;width:350px;}
}
@keyframes discord-c {
	0% {height:500px;width:350px;}
    100% {height:70px; width:250px;}
}
.discord {
	animation-name: discord-c;
	animation-duration: 2s;
	animation-play-state: pause;
	float:left;
    height:6%;
	width:50%;
   
	
}



.discord:hover {
	height:500px;
	width:350px;
	float:left;
	animation-name: discord;
	animation-duration: 2s;
	animation-play-state: pause;
}

.HomeContainer{

    width: 100%;
    height:100%;
	/* background-color: #647C90; */
	color:black;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
	align-items: center;
	overflow-y: scroll;

}
.textBoxxStyle {

    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;; */
    /* color:rgba(0, 0, 0, 0.6); */
    color:rgba(0, 0, 0, 0.87);

    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    padding-block: 5%;
    /* padding-block:1%;
    align-items: center;
    align-self: flex-start; */

}

.stripeFormatting{
justify-content: space-between;
display: flex;
margin-top: 2%;
padding-block: 2%;
width:100%;

}
